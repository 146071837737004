import React, { createContext, useState, useEffect } from 'react';
import { Plugins } from '@capacitor/core';
import axios from 'axios';
import { Banner } from './models/Banner';
import { Usuario } from './models/Usuario';
import Log from './components/Log';

const { Storage } = Plugins;

const AVANZAR = 'avanzar';
const TOKEN = 'token';
const USUARIO = 'id_usuario';
const TUTORIAL = 'tutorial';  

export interface AppContextState {
  vioTutorial: boolean,
  menuEnabled: boolean,
  autoAvanzar: boolean,
  banners: Banner[],
  esquema: any;
  token?: string,
  usuario?: Usuario,
  setVioTutorial: (value: boolean) => void;
  setMenuEnabled: (value: boolean) => void;
  setAutoAvanzar: (value: boolean) => void;
  setBanners: (value: Banner[]) => void;
  setEsquema: (value: any) => void;
  setToken: (value: string|undefined) => void;
  setUsuario: (value: Usuario|undefined) => void;
  isLoading: () => boolean;
  isLogged: () => boolean;
  getBanner: (ubicacion: string) => string | null;
}

export const AppContextDefaultState = {
  vioTutorial: false,
  menuEnabled: true,
  autoAvanzar: false,
  banners: [],
  esquema: null,
  token: undefined,
  usuario: undefined,
  setVioTutorial: (_value: boolean): void => {},
  setMenuEnabled: (_value: boolean): void => {},
  setAutoAvanzar: (_value: boolean): void => {},
  setBanners: (_value: Banner[]): void => {},
  setEsquema: (_value: any): void => {},
  setToken: (_value: string|undefined): void => {},
  setUsuario: (_value: Usuario|undefined): void => {},
  isLoading: () => { return false },
  isLogged: () => { return false },
  getBanner: (ubicacion: string): string | null => { return ubicacion }
};

export const AppContext = createContext<AppContextState>(AppContextDefaultState);

export const AppContextProvider: React.FC = (props => {

  const [vioTutorial, setVioTutorial] = useState(AppContextDefaultState.vioTutorial);
  const [menuEnabled, setMenuEnabled] = useState(AppContextDefaultState.menuEnabled);
  const [autoAvanzar, setAutoAvanzar] = useState(AppContextDefaultState.autoAvanzar);
  const [cargoEsquema, setCargoEsquema] = useState(false);
  const [cargoUsuario, setCargoUsuario] = useState(false);
  const [banners, setBanners] = useState<Banner[]>(AppContextDefaultState.banners);
  const [esquema, setEsquema] = useState(AppContextDefaultState.esquema);
  const [token, setToken] = useState<string>();
  const [usuario, setUsuario] = useState<Usuario>();
  
  const isLoading = () => !(cargoEsquema && cargoUsuario);

  const isLogged = () => {
    if (usuario && token) {
      return true;  
    }
    return false;
  }

  const getBanner = (ubicacion: string) => {
    return banners.find(x => x.id === ubicacion)?.imagen1 || null;
  }
  
  useEffect(() => {
    const reqOne = axios.get(`${process.env.REACT_APP_API_URL}/banners`);
    const reqTwo = axios.get(`${process.env.REACT_APP_API_URL}/esquema`);
    axios.all([reqOne, reqTwo]).then(axios.spread((...responses) => {
      // console.log("AppContext.esquema ", responses[1].data);
      setBanners(responses[0].data);
      setEsquema(responses[1].data);
      setCargoEsquema(true);
    })).catch(errors => {
      Log.error(
        Array.isArray(errors) && errors.length > 0 ? errors[0] : new Error("Error de conexión a la API"),
        usuario,
        `AppContext.tsx » init(): Error al intentar leer /banners y /esquema`
      );
      setCargoEsquema(true);
    });    
    const getDataEnLocalStorage = async () => {
      const response = await Promise.all([
        Storage.get({ key: AVANZAR }),
        Storage.get({ key: TUTORIAL }),
        Storage.get({ key: TOKEN }),
        Storage.get({ key: USUARIO })
      ]);
      const _autoAvanzar = response[0].value === 'true';
      const _vioTutorial = response[1].value === 'true';
      const _token = response[2].value || undefined;
      const _idUsuario = response[3].value || undefined;
      if (_autoAvanzar) {
        setAutoAvanzar(true);
      }      
      if (_vioTutorial) {
        setVioTutorial(true);
      }
      if (_token) {
        setToken(_token);
      }      
      if (_idUsuario) {
        // console.log("Buscando _idUsuario > ", _idUsuario);
        const url = `${process.env.REACT_APP_API_URL}/usuarios/${_idUsuario}`;
        axios.get(url)
          .then((res) => {
            const _usuario = res.data.usuario;
            // console.dir(_usuario);
            setUsuario(_usuario);
            setCargoUsuario(true);
          }).catch((err) => {
            Log.error(
              err,
              undefined,
              `AppContext.tsx » init(): Error al buscar al usuario ${_idUsuario}`
            );
            setCargoUsuario(true);
          });
      } else {
        // console.log("_idUsuario > NULL");
        setCargoUsuario(true);
      }
    }
    getDataEnLocalStorage();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (token) {
      // console.log("HAY token", token);
      Storage.set({ key: TOKEN, value: token });
    } else {
      Storage.remove({ key: TOKEN });
    }
  }, [token]);

  useEffect(() => {
    if (usuario && usuario.id) {
      // console.log("HAY usuario", usuario);
      Storage.set({ key: USUARIO, value: usuario.id.toString() });
    } else {
      Storage.remove({ key: USUARIO });
    }
  }, [usuario]);

  useEffect(() => {
    Storage.set({ key: TUTORIAL, value: JSON.stringify(vioTutorial) });
  }, [vioTutorial]);

  useEffect(() => {
    Storage.set({ key: AVANZAR, value: JSON.stringify(autoAvanzar) });
  }, [autoAvanzar]);

  return (
    <AppContext.Provider value={{
      vioTutorial: vioTutorial,
      menuEnabled: menuEnabled,
      autoAvanzar: autoAvanzar,
      banners: banners,
      esquema: esquema,
      token: token,
      usuario: usuario,
      isLoading: isLoading,
      isLogged: isLogged,
      getBanner: getBanner,
      setVioTutorial: setVioTutorial,
      setMenuEnabled: setMenuEnabled,
      setAutoAvanzar: setAutoAvanzar,
      setBanners: setBanners,
      setEsquema: setEsquema,
      setToken: setToken,
      setUsuario: setUsuario,
    }}>
      {props.children}
    </AppContext.Provider>
  )
});
