import React, { useState, useEffect, useContext } from 'react';
import { IonToolbar, IonContent, IonPage, IonButtons, IonFab, IonFabButton, IonTitle, IonMenuButton, IonIcon, IonToast, IonModal, IonHeader, IonSpinner, useIonAlert, IonLoading, IonButton, IonBackdrop } from '@ionic/react';
import { add, searchOutline } from 'ionicons/icons';
import './Practicas.scss'
import NuevaPractica from './NuevaPractica';
import axios from 'axios';
import { Practica } from '../models/Practica';
import { AppContext } from '../AppContext';
import LogoAaot from '../components/LogoAaot';
import MensajeError from '../components/MensajeError';
import ListaDePracticas from '../components/ListaDePracticas';
import SinPracticas from '../components/SinPracticas';
import { Modulo } from '../models/Modulo';
import Log from '../components/Log';
import SponsorPage from './Sponsor';

const PracticasPage: React.FC = () => {

  const [showCargaModal, setShowCargaModal] = useState(false);
  const [showSponsor, setShowSponsor] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");
  const {
    usuario
  } = useContext(AppContext);

  const [practicas, setPracticas] = useState<Practica[]>([]);
  const [showMsg, setShowMsg] = useState("");
  const [practicaActual, setPracticaActual] = useState<Practica>();

  const [present] = useIonAlert();

  const onNewPractica = () => {
    setPracticaActual(undefined);
    setShowCargaModal(true);
  };

  const onEditPractica = (practica: Practica) => {
    // console.log("onEditPractica", practica);
    setError("");
    setLoading("Cargando Práctica...");
    const url = `${process.env.REACT_APP_API_URL}/practicas/modulos?$inlinecount=allpages&$filter=id_practica eq ${practica.id}`;
    axios.get(url)
      .then((res) => {
        // console.dir(res.data);
        const items = res.data.Items;
        const lista = items.map((x: Modulo) => { return {...x, id: `${x.id_modulo}-${Math.random().toString().substring(2, 8)}` }});
        // console.dir(lista);
        setPracticaActual({...practica, modulos: lista});
        setShowCargaModal(true);
        setLoading("");
      }).catch((err) => {
        Log.error(
          err,
          usuario,
          `Practicas.tsx » cargarPracticas(): Error al intentar leer los Módulos de la Práctica ${practica.id}`
        );
        setError("Error al intentar leer las Prácticas");
        setLoading("");
      });
  };

  const onDeletePractica = (practica: Practica) => {
    present({
      header: 'Borrar Práctica',
      message: `¿Realmente desea borrar esta Práctica del día ${practica.fecha} en la institución ${practica.institucion}?`,
      buttons: [
        'Cancelar',
        { text: 'Sí, borrar', handler: (d) => onRequestDelete(practica) },
      ]
    });
  };

  const cargarPracticas = () => {
    setError("");
    setLoader(true);
    const url = `${process.env.REACT_APP_API_URL}/practicas?residente=${usuario?.id}&editables=si`;
    axios.get(url)
      .then((res) => {
        // console.dir(res.data.data);
        setPracticas(res.data.data);
        setLoader(false);
      }).catch((err) => {
        Log.error(
          err,
          usuario,
          `Practicas.tsx » cargarPracticas(): Error al intentar leer las Prácticas del usuario ${usuario?.id}`
        );
        setError("Error al intentar leer las Prácticas");
        setLoader(false);
      });
  }

  useEffect(() => {
    cargarPracticas();
    // eslint-disable-next-line
  }, [usuario]);

  const onCloseNuevaPractica = (practica: Practica | undefined) => {
    setShowCargaModal(false);
    if (practica) {
      // console.dir(practica);
      setError("");
      if (practicaActual) {
        // EDICIÓN
        // console.log("onCloseNuevaPractica(EDICIÓN)");
        setLoading("Actualizando práctica...");
        axios.put(
          `${process.env.REACT_APP_API_URL}/practicas/${practica.id}`, practica)
          .then(async () => {
            setLoading("");
            setShowMsg("Práctica actualizada");
            cargarPracticas();
          }).catch((err) => {
            Log.error(
              err,
              usuario,
              `Practicas.tsx » onCloseNuevaPractica(UPDATE): Error al intentar actualizar la Práctica: ${JSON.stringify(practica)}`
            );
            setError("Error al intentar actualizar la Práctica");
            setLoading("");
          });
      } else {
        // ALTA
        // console.log("onCloseNuevaPractica(ALTA)");
        setLoading("Creando práctica...");
        axios.post(
          `${process.env.REACT_APP_API_URL}/practicas`, practica)
          .then(async () => {
            setLoading("");
            setShowMsg("Práctica creada");
            cargarPracticas();
          }).catch((err) => {
            Log.error(
              err,
              usuario,
              `Practicas.tsx » onCloseNuevaPractica(CREATE): Error al intentar crear la Práctica: ${JSON.stringify(practica)}`
            );
            setError("Error al intentar crear la nueva Práctica");
            setLoading("");
          });
      }
    }
  };

  const onRequestDelete = (practica: Practica) => {
    // console.log("onRequestDelete!", practica);
    setError("");
    setLoading("Borrando práctica...");
    axios.delete(
      `${process.env.REACT_APP_API_URL}/practicas/${practica.id}`)
      .then(async () => {
        setLoading("");
        cargarPracticas();
        setShowMsg("Práctica borrada");
      }).catch((err) => {
        Log.error(
          err,
          usuario,
          `Practicas.tsx » onRequestDelete(): Error al intentar borrar la Práctica ${practica.id}`
        );
        setError("Error al intentar borrar la Práctica");
        setLoading("");
      });
  };

  return (
    <IonPage id="practicas-page">
      <IonHeader translucent={true} no-border>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <LogoAaot />
          <IonTitle>Prácticas</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <IonToolbar className="toolbar-sponsor ion-no-padding" style={{ cursor: "pointer" }} onClick={() => setShowSponsor(true)}>
          <img src={`${process.env.REACT_APP_API_FOTOS}/novedades-bago.jpg`} alt="" />
          <IonButtons slot="end">
            <IonButton fill="outline" shape="round" size="small" color="primary" onClick={() => setShowSponsor(true)}>
              <IonIcon slot="icon-only" icon={searchOutline} size="small" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {
          error && <MensajeError mensaje={error} onClose={() => setError("")} />
        }
        {
          loader
            ? (
              <IonSpinner name="crescent" color="secondary" />
            ) : (
              practicas.length === 0
                ? <SinPracticas imagen="/assets/img/vacio-practicas.png" mensaje="No hay prácticas editables" />
                : <ListaDePracticas
                  data={practicas}
                  onEdit={onEditPractica}
                  onDelete={onDeletePractica}
                />
            )
        }
        <IonLoading
          isOpen={loading !== ""}
          onDidDismiss={() => setLoading("")}
          message={loading}
          duration={60000}
        />
        <IonToast
          isOpen={showMsg !== ""}
          message={showMsg}
          duration={2000}
          onDidDismiss={() => setShowMsg("")}
        />
      </IonContent>
      <IonModal
        isOpen={showCargaModal}
        onDidDismiss={() => setShowCargaModal(false)}
        swipeToClose={true}
      >
        <NuevaPractica
          practicaActual={practicaActual}
          onDismissModal={onCloseNuevaPractica}
        />
      </IonModal>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton color="primary" onClick={onNewPractica}>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
      <IonModal
        swipeToClose={true}
        isOpen={showSponsor}
        className="modal-sponsor"
        onDidDismiss={() => setShowSponsor(false)}
      >
        <IonBackdrop
          visible={showSponsor}
        />
        <SponsorPage
          onDismissModal={() => setShowSponsor(false)}
        />
      </IonModal>
    </IonPage>
  );
};

export default PracticasPage;
