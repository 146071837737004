import React, { useContext } from 'react';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react';
import { Route, Redirect, Switch } from 'react-router';
import { newspaperOutline, peopleOutline, megaphoneOutline, lockClosedOutline, businessOutline } from 'ionicons/icons';
import Usuarios from './Usuarios';
import Administradores from './Administradores';
import Banners from './Banners';
import Instituciones from './Instituciones';
import Mensajes from './Mensajes';
import { AppContext } from '../AppContext';

interface AdminTabsProps { }

const AdminTabs: React.FC<AdminTabsProps> = () => {

  const { isLogged, usuario } = useContext(AppContext);

  return (
    !isLogged()
      ? <Redirect to="/" />
      : (
        !usuario?.administrador
          ? <Redirect to="/app/novedades" />
          : (
            <IonTabs>
              <IonRouterOutlet>
                <Switch>
                  <Route path="/admin/novedades" component={Mensajes} exact />
                  <Route path="/admin/instituciones" component={Instituciones} exact />
                  <Route path="/admin/sponsors" component={Banners} exact />
                  <Route path="/admin/administradores" component={Administradores} exact />
                  <Route path="/admin/usuarios" component={Usuarios} exact />
                  <Redirect to="/admin/novedades" />
                </Switch>
              </IonRouterOutlet>
              <IonTabBar slot="bottom">
                <IonTabButton tab="novedades" href="/admin/novedades">
                  <IonIcon icon={newspaperOutline} />
                  <IonLabel>Novedades</IonLabel>
                </IonTabButton>
                <IonTabButton tab="instituciones" href="/admin/instituciones">
                  <IonIcon icon={businessOutline} />
                  <IonLabel>Instituciones</IonLabel>
                </IonTabButton>
                <IonTabButton tab="banners" href="/admin/sponsors">
                  <IonIcon icon={megaphoneOutline} />
                  <IonLabel>Sponsors</IonLabel>
                </IonTabButton>
                <IonTabButton tab="administradores" href="/admin/administradores">
                  <IonIcon icon={lockClosedOutline} />
                  <IonLabel className="ion-hide-sm-up">Admins</IonLabel>
                  <IonLabel className="ion-hide-sm-down">Administradores</IonLabel> 
                </IonTabButton>
                <IonTabButton tab="usuarios" href="/admin/usuarios">
                  <IonIcon icon={peopleOutline} />
                  <IonLabel>Usuarios</IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
          )
      )
  );
};

export default AdminTabs;
