import React, { useContext, useState } from 'react';
import { IonLoading, IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonGrid, IonRow, IonCol, IonButton, IonList, IonItem, IonLabel, IonInput, IonText, IonThumbnail, IonRouterContext, IonFooter, IonIcon, IonNote, IonChip } from '@ionic/react';
import './Login.scss';
import Registro from './Registro';
import axios from 'axios';
import RecuperarPassword from './RecuperarPassword';
import { AppContext } from '../AppContext';
import { Usuario } from '../models/Usuario';
import { alertCircleOutline, closeOutline, headsetOutline } from 'ionicons/icons';
import ContactoModal from './ContactoModal';
import { Controller, useForm } from 'react-hook-form';
import { PASSWORD_LARGO } from '../constantes';
import { version } from '../../package.json';
import Log from '../components/Log';

const Login: React.FC = () => {
  const ionRouterContext = useContext(IonRouterContext);
  const {
    setToken,
    setUsuario
  } = useContext(AppContext);
  const [showRegistro, setShowRegistro] = useState(false);
  const [showRecupero, setShowRecupero] = useState(false);
  const [showContacto, setShowContacto] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { control, handleSubmit, formState: { errors /*, isValid */ } } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      id: "",
      password: ""
    }
  });

  const onSubmit = (data: any) => {
    const datos = {
      "email": data.id,
      "password": data.password
    };
    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, datos)
      .then((data) => {
        const _token = data.data.access_token;
        const _usuario = data.data.usuario;
        const usuario: Usuario = {
          id: _usuario.id,
          id_cliente: _usuario.id_cliente,
          nombre_completo: _usuario.nombre_completo,
          nombre: _usuario.nombre,
          email: _usuario.email,
          foto: _usuario.foto,
          leyenda: _usuario.leyenda,
          id_clasificacion: _usuario.id_clasificacion,
          clasificacion: _usuario.clasificacion,
          fecha_nacimiento: _usuario.fecha_nacimiento,
          fecha_ingreso: _usuario.fecha_ingreso,
          fecha_egreso: _usuario.fecha_egreso,
          id_institucion: _usuario.id_institucion,
          administrador: _usuario.administrador === 1,
          activo: _usuario.activo === 1,
          residencia: _usuario.residencia,
          provincia: _usuario.provincia
        };
        // console.log('Usuario » ', usuario);
        // console.log('Token » ', _token);
        setError('');
        setLoading(false);
        setToken(_token);
        setUsuario(usuario);
        ionRouterContext.push('/app');
      }).catch((err) => {
        console.dir(err);
        let msg = `Error ${err?.response?.status || "desconocido"} al intentar esta operación`;
        const status = err?.response?.status || 0;
        switch (status) {
          case 404:
            msg = "Usuario inexistente";
            break;
          case 401:
            msg = "Por favor, revise las credenciales";
            break;
          default:
            break;
        }
        if (err.message === "Network Error") {
          msg = `No es posible comunicarse con la App en este momento.`;
          Log.error(
            err,
            undefined,
            `Login.tsx » onSubmit(): Error al intentar ingresar a la app con los datos: ${JSON.stringify(datos)}`
          );
        }
        setError(msg);
        setLoading(false);
      });
  };

  return (
    <IonPage id="login-page">
      <IonHeader translucent={false} className="ion-no-border ion-hide-sm-up">
        <IonToolbar color="primary">
          <IonThumbnail slot="start" style={{ marginLeft: 15, padding: '0 0 3px' }}>
            <img
              alt=""
              style={{ objectFit: "contain" }}
              src="/assets/img/aaot-transparente-apple.png"
            />
          </IonThumbnail>
          <IonTitle>App de Residentes</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonGrid className="ion-no-padding">
            <IonRow className="ion-no-padding">
              <IonCol no-padding size="12" size-sm="6" className="ion-hide-sm-down">
                <div className="login-logo">
                  <img src="assets/img/aaot-blanco-comprimida.jpg" alt="" />
                  <IonText color="primary">
                    <h1>App de Residentes</h1>
                  </IonText>
                  <IonText color="dark">
                    <h5>Carga de Prácticas de Residencias desde cualquier lugar</h5>
                  </IonText>
                </div>
              </IonCol>
              <IonCol className="ion-no-padding" size="12" size-sm="6">
                <div style={{ textAlign: "center" }}>
                  <img
                    alt=""
                    className="logo-sponsor ion-hide-sm-up"
                    src={`${process.env.REACT_APP_API_FOTOS}/ingreso_mobile_v2.jpg`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src="assets/img/ingreso_mobile.jpg";
                    }}
                  />
                  <img
                    alt=""
                    className="logo-sponsor ion-hide-sm-down"
                    src={`${process.env.REACT_APP_API_FOTOS}/ingreso_pc_v2.jpg`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src="assets/img/ingreso_pc.jpg";
                    }}
                  />
                </div>
                {/* TODO <form noValidate onSubmit={login} style={{ padding: '0 10px 15px' }}> */}
                <div style={{ padding: '0 10px 15px' }}>
                  <IonList>
                    <IonItem className={errors.id ? "ion-invalid" : ""}>
                      <IonLabel position="stacked">Email ó DNI</IonLabel>
                      <Controller
                        render={({ field }) => (
                          <IonInput
                            {...field}
                            type="text"
                            spellCheck={false}
                            autocapitalize="off"
                            autocomplete="off"
                            onIonBlur={() => field.onBlur()}
                            onIonChange={(e) => field.onChange(e.detail.value)}
                            placeholder="Ingrese su Email ó DNI"
                          />
                        )}
                        control={control}
                        name="id"
                        rules={{
                          required: {
                            value: true,
                            message: "El Email ó DNI es requerido"
                          },
                          minLength: {
                            value: PASSWORD_LARGO,
                            message: `Al menos ${PASSWORD_LARGO} caracteres`
                          }
                        }}
                      />
                      <IonNote slot="error">{errors?.id?.message}</IonNote>
                    </IonItem>
                    <IonItem className={errors.password ? "ion-invalid" : ""}>
                      <IonLabel position="stacked">Contraseña</IonLabel>
                      <Controller
                        render={({ field }) => (
                          <IonInput
                            {...field}
                            type="password"
                            autocomplete="off"
                            onIonBlur={() => field.onBlur()}
                            onIonChange={(e) => field.onChange(e.detail.value)}
                            placeholder="Ingrese su contraseña"
                          />
                        )}
                        control={control}
                        name="password"
                        rules={{
                          required: {
                            value: true,
                            message: "La Contraseña es requerida"
                          },
                          minLength: {
                            value: PASSWORD_LARGO,
                            message: `Al menos ${PASSWORD_LARGO} caracteres`
                          }
                        }}
                      />
                      <IonNote slot="error">{errors?.password?.message}</IonNote>
                    </IonItem>
                  </IonList>
                  <IonRow style={{ paddingTop: 10 }}>
                    <IonCol>
                      <IonButton
                        type="submit"
                        expand="block"
                        mode="ios"
                      >
                        Ingresar
                      </IonButton>
                    </IonCol>
                    <IonCol>
                      <IonButton color="light" expand="block" mode="ios"
                        onClick={() => setShowRegistro(true)}
                      >
                        Registro
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  {
                    error && (
                      <IonRow>
                        <IonCol>
                          <IonChip color="danger" onClick={() => setError("")} style={{ width: '100%' }}>
                            <IonIcon icon={alertCircleOutline} />
                            <IonLabel style={{ width: '100%' }}>{error}</IonLabel>
                            <IonIcon icon={closeOutline} />
                          </IonChip>
                        </IonCol>
                      </IonRow>
                    )
                  }
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonLoading
            isOpen={loading}
            onDidDismiss={() => setLoading(false)}
            message={"Ingresando..."}
            duration={60000}
          />
        </form>
      </IonContent>
      <IonFooter translucent className="ion-no-border">
        <IonGrid>
          <IonRow>
            <IonCol size="6" className="ion-hide-sm-down" style={{
              paddingLeft: 10,
              paddingTop: 24,
              color: '#666'
            }}>
              <small>
                {version}
              </small>
            </IonCol>
            <IonCol sizeXs="6" sizeSm="3">
              <IonButton fill="clear" mode="ios" onClick={() => setShowRecupero(true)}>
                ¿Olvidó su contraseña?
              </IonButton>
            </IonCol>
            <IonCol sizeXs="6" sizeSm="3" style={{ textAlign: "right" }}>
              <IonButton fill="clear" mode="ios" onClick={() => setShowContacto(true)}>
                Contacto
                <IonIcon slot="end" icon={headsetOutline} />
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
      <IonModal
        isOpen={showRegistro}
        onDidDismiss={() => setShowRegistro(false)}
        swipeToClose={true}
      >
        <Registro
          dismissModal={() => setShowRegistro(false)}
          openRecupero={() => setShowRecupero(true)}
        />
      </IonModal>
      <IonModal
        isOpen={showRecupero}
        onDidDismiss={() => setShowRecupero(false)}
        swipeToClose={true}
      >
        <RecuperarPassword
          onDismissModal={() => setShowRecupero(false)}
        />
      </IonModal>
      <IonModal
        isOpen={showContacto}
        onDidDismiss={() => setShowContacto(false)}
        swipeToClose={true}
      >
        <ContactoModal
          onDismissModal={() => setShowContacto(false)}
        />
      </IonModal>
    </IonPage>
  );
};

export default Login;
