import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonHeader, IonToolbar, IonAvatar } from '@ionic/react';
import { newspaperOutline, calendarOutline, logOut, mapOutline, person, headsetOutline, podiumOutline, peopleOutline, megaphoneOutline, lockClosedOutline, businessOutline, logoYoutube } from 'ionicons/icons';
import { AppContext } from '../AppContext';
import './Menu.scss'

const routes = {
  appPages: [
    { title: 'Novedades', path: '/app/novedades', icon: newspaperOutline },
    { title: 'Prácticas', path: '/app/practicas', icon: calendarOutline },
    { title: 'Estadísticas', path: '/app/estadisticas', icon: podiumOutline },
    { title: 'Tutorial', path: '/tutorial', icon: mapOutline },
    { title: 'Video tutorial', path: '/videotutorial', icon: logoYoutube },
    { title: 'Cuenta', path: '/cuenta', icon: person },
    { title: 'Contacto', path: '/contacto', icon: headsetOutline },
    { title: 'Cerrar sesión', path: '/logout', icon: logOut },
  ],
  adminPages: [
    { title: 'Novedades', path: '/admin/novedades', icon: newspaperOutline },
    { title: 'Instituciones', path: '/admin/instituciones', icon: businessOutline },
    { title: 'Sponsors', path: '/admin/sponsors', icon: megaphoneOutline },    
    { title: 'Administradores', path: '/admin/administradores', icon: lockClosedOutline },
    { title: 'Usuarios', path: '/admin/usuarios', icon: peopleOutline }
  ]
};

interface Pages {
  title: string,
  path: string,
  icon: string,
  routerDirection?: string
}

const Menu: React.FC = () => {
  
  const location = useLocation();
  const {
    menuEnabled,
    usuario
  } = useContext(AppContext);

  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem
            no-padding
            detail={false}
            routerLink={p.path}
            routerDirection="none"
            className={location.pathname.startsWith(p.path) ? 'selected' : undefined}
          >
            <IonIcon slot="start" icon={p.icon} size="small" />
            <IonLabel no-padding>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }
  
  const renderAdministracion = () => {
    if (usuario?.administrador) {
      return (
        <IonList lines="none">
          <IonListHeader>Administración</IonListHeader>
          {renderlistItems(routes.adminPages)}
        </IonList>
      );
    }
    return null;
  };
  
  return (
    <IonMenu type="overlay" contentId="main" disabled={!menuEnabled}>
      <IonHeader translucent={true} no-border>
        <IonToolbar color="primary">
          <IonList lines="none" className="titulo">
            <IonItem color="primary" className="titulo">
              <IonAvatar slot="start">
                <img src={
                  usuario?.foto
                  ? `${process.env.REACT_APP_API_FOTOS}/${usuario.foto}`
                  : "assets/img/usuario.png"
                } alt="" />
              </IonAvatar>
              <IonLabel>Hola {usuario?.nombre}</IonLabel>
            </IonItem>
          </IonList>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonListHeader>App de Residentes</IonListHeader>
          {renderlistItems(routes.appPages)}
        </IonList>
        {renderAdministracion()}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
