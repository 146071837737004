import React, { useContext, useEffect, useState } from 'react';
// IonCardHeader, IonLabel, IonItem, IonCard, IonCardContent, IonGrid, IonRow, IonCol, IonAccordion, IonAccordionGroup
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonSpinner, IonModal, IonButton, IonIcon } from '@ionic/react';
import './Estadisticas.scss';
// import Chart from "react-apexcharts";
import { AppContext } from '../AppContext';
import axios from 'axios';
import { Practica } from '../models/Practica';
import GrillaDePracticas from '../components/GrillaDePracticas';
import PracticasFiltros from '../components/PracticasFiltros';
import { filterOutline, searchOutline } from 'ionicons/icons';
import LogoAaot from '../components/LogoAaot';
import { defaultFiltros, filtrar, Filtros } from '../models/Filtros';
import Log from '../components/Log';
import SponsorPage from './Sponsor';
// import { format } from 'date-fns';

// Charts
// https://apexcharts.com/docs/react-charts/
// https://apexcharts.com/react-chart-demos/
// https://blog.logrocket.com/charting-react-apexcharts/
// https://apexcharts.com/react-chart-demos/sparklines/basic/
// Error en legend
// https://github.com/apexcharts/react-apexcharts/issues/347#issuecomment-932848265

// Paginación?
// https://www.sqlshack.com/learn-mysql-what-is-pagination/
// https://stackoverflow.com/questions/3799193/mysql-data-best-way-to-implement-paging

// const defaultBarOptions = () => {
//   return {
//     chart: {
//       // height: 500
//     },
//     xaxis: {
//       categories: []
//     },
//     yaxis: {
//       show: false
//     }
//   }
// };

// const defaultBarSeries = () => [
//   {
//     name: "prácticas",
//     data: []
//   }
// ];

// const defaultPieSeries = () => [];

// const defaultPieOptions = () => {
//   return {
//     chart: {
//       // height: 500,
//       width: '100%',
//       legend: {
//         position: 'right'
//       }
//     },
//     labels: [],
//     // responsive: [{
//     //   breakpoint: 480,
//     //   options: {
//     //     chart: {
//     //       // width: 240
//     //     },
//     //     legend: {
//     //       position: 'right'
//     //     }
//     //   }
//     // }]
//   };
// }

const Estadisticas: React.FC = () => {

  const [filtros, setFiltros] = useState<Filtros>();
  const [practicas, setPracticas] = useState<Practica[]>([]);
  const [showSponsor, setShowSponsor] = useState(false);
  const [showFiltros, setShowFiltros] = useState(false);
  const [loading, setLoading] = useState(false);
  // TODO const [error, setError] = useState("");
  // const [barOptions, setBarOptions] = useState(defaultBarOptions());
  // const [barSeries, setBarSeries] = useState(defaultBarSeries());
  // const [pieSeries, setPieSeries] = useState(defaultPieSeries());
  // const [pieOptions, setPieOptions] = useState(defaultPieOptions());
  const {
    usuario
  } = useContext(AppContext);

  useEffect(() => {
    if (usuario) {
      setFiltros(defaultFiltros(usuario));
    }
  }, [usuario]);

  useEffect(() => {
    if (!filtros) {
      return;
    }
    // console.log("FILTROS! > ", filtros);
    const filter = filtrar(filtros);
    // console.dir(filter);
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/practicas?${filter}`;
    // console.log(url);
    axios.get(url)
      .then((res) => {
        // console.dir(res.data.data);
        setPracticas(res.data.data);
        // const fechas = res.data.fechas;
        // const modulos = res.data.modulos;
        // // console.dir(fechas);
        // // console.dir(modulos);
        // let barOpt = defaultBarOptions();
        // barOpt.xaxis.categories = fechas.map((x: any) => x.mes);
        // setBarOptions(barOpt);
        // let barSer = defaultBarSeries();
        // barSer[0].data = fechas.map((x: any) => x.total);
        // setBarSeries(barSer);
        // let pieOpt = defaultPieOptions();
        // pieOpt.labels = modulos.map((x: any) => x.modulo);
        // setPieOptions(pieOpt);
        // let pieSer = defaultPieSeries();
        // pieSer = modulos.map((x: any) => x.total);
        // setPieSeries(pieSer);
        // window.dispatchEvent(new Event('resize'));
        setLoading(false);
      }).catch((err) => {
        Log.error(
          err,
          usuario,
          `Estadisticas.tsx » useEffect(filtros): Error al intentar filtrar con '${filtros}'`
        );
        setLoading(false);
      });
  // eslint-disable-next-line
  }, [filtros]);

  const onCloseFiltros = (nuevosFiltros: Filtros | null) => {
    setShowFiltros(false);
    if (nuevosFiltros) {
      setFiltros(nuevosFiltros);
    }
  };

  return (
    <IonPage id="estadisticas">
      <IonHeader translucent={true} no-border>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <LogoAaot />
          <IonTitle>Estadísticas</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowFiltros(true)}>
              Filtros
              <IonIcon icon={filterOutline} slot="end" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <IonToolbar className="toolbar-sponsor ion-no-padding" style={{ cursor: "pointer" }} onClick={() => setShowSponsor(true)}>
          <img src={`${process.env.REACT_APP_API_FOTOS}/novedades-bago.jpg`} alt="" />
          <IonButtons slot="end">
            <IonButton fill="outline" shape="round" size="small" color="primary" onClick={() => setShowSponsor(true)}>
              <IonIcon slot="icon-only" icon={searchOutline} size="small" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {
          loading
            ? (
              <IonSpinner name="crescent" color="secondary" />
            ) : (
              <GrillaDePracticas
                data={practicas}
              />
              // <>
              //   <IonAccordionGroup multiple={true} value={[]} expand="inset">
              //     <IonAccordion value="charts">
              //       <IonItem slot="header">
              //         <IonLabel>Gráficas</IonLabel>
              //       </IonItem>
              //       <IonGrid fixed slot="content">
              //         <IonRow>
              //           <IonCol size="12" size-md="6">
              //             {/* minHeight: 435, */}
              //             <IonCard no-margin no-padding style={{ boxShadow: "none", border: "1px solid #ddd" }}>
              //               <IonCardHeader>
              //                 <IonItem lines="none">
              //                   <IonLabel>
              //                     <h1>Prácticas por Mes</h1>
              //                     {
              //                       filtros && filtros.desde && filtros.hasta && (
              //                         <p>{`en el período ${format(filtros.desde, "dd/MM/yy")} » ${format(filtros.hasta, "dd/MM/yy")}`}</p>
              //                       )
              //                     }
              //                   </IonLabel>
              //                 </IonItem>
              //               </IonCardHeader>
              //               <IonCardContent>
              //                 <Chart
              //                   options={barOptions}
              //                   series={barSeries}
              //                   type="bar"
              //                   width="100%"
              //                 />
              //               </IonCardContent>
              //             </IonCard>
              //           </IonCol>
              //           <IonCol size="12" size-md="6">
              //             {/* minHeight: 435, */}
              //             <IonCard no-padding style={{ boxShadow: "none", border: "1px solid #ddd" }}>
              //               <IonCardHeader>
              //                 <IonItem lines="none">
              //                   <IonLabel>
              //                     <h1>Prácticas por Módulo</h1>
              //                     <p>&nbsp;</p>
              //                   </IonLabel>
              //                 </IonItem>
              //               </IonCardHeader>
              //               <IonCardContent>
              //                 <Chart
              //                   type="pie"
              //                   options={pieOptions}
              //                   series={pieSeries}
              //                   width="100%"
              //                 />
              //               </IonCardContent>
              //             </IonCard>
              //           </IonCol>
              //         </IonRow>
              //       </IonGrid>
              //     </IonAccordion>
              //   </IonAccordionGroup>
              //   <IonAccordionGroup multiple={true} value={['planilla']} expand="inset">
              //     <IonAccordion value="planilla">
              //       <IonItem slot="header">
              //         <IonLabel>Prácticas</IonLabel>
              //       </IonItem>
              //       <div slot="content" style={{ width: '100%' }}>
              //         <GrillaDePracticas
              //           data={practicas}
              //         />
              //       </div>
              //     </IonAccordion>
              //   </IonAccordionGroup>
              // </>
            )
        }
      </IonContent>
      <IonModal
        swipeToClose={true}
        isOpen={showSponsor}
        className="modal-sponsor"
        onDidDismiss={() => setShowSponsor(false)}
      >
        <SponsorPage
          onDismissModal={() => setShowSponsor(false)}
        />
      </IonModal>
      <IonModal
        isOpen={showFiltros}
        swipeToClose={true}
      >
        {
          filtros && (
            <PracticasFiltros
              filtros={filtros}
              onDismissModal={onCloseFiltros}
            />
          )
        }
      </IonModal>
    </IonPage>
  );
};

export default Estadisticas;
