import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonText, IonMenuButton, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonIcon, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonRefresher, IonRefresherContent, IonToast, IonSpinner, IonButton, IonModal } from '@ionic/react';
import './Novedades.scss';
import axios from 'axios';
import { AppContext } from '../AppContext';
// import { format } from 'date-fns'
// https://codesandbox.io/s/date-fns-example-pvkpn?file=/src/index.js
import { format, parse } from 'date-fns'
// import { es } from 'date-fns/locale'
import { Novedad } from '../models/Novedad';
import LogoAaot from '../components/LogoAaot';
import { pinOutline, searchOutline } from 'ionicons/icons';
import Log from '../components/Log';
import SponsorPage from './Sponsor';

/* https://www.positronx.io/ionic-card-component-example/ */

const Novedades: React.FC = () => {

  const [novedades, setNovedades] = useState<Array<Novedad>>([]);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [refreshing, setRefreshing] = useState(false);
  const [showSponsor, setShowSponsor] = useState(false);
  const [showCompleteToast, setShowCompleteToast] = useState(false);

  const {
    isLoading,
    usuario
  } = useContext(AppContext);

  const doRefresh = (mensaje: boolean = true) => {
    setRefreshing(true);
    axios.get(`${process.env.REACT_APP_API_URL}/novedades?activas`)
      .then((res) => {
        const items: Array<Novedad> = res.data;
        // console.dir(items);
        setNovedades(items);
        if (mensaje) {
          setShowCompleteToast(true);
        }
        setRefreshing(false);
      }).catch((err) => {
        Log.error(
          err,
          usuario,
          `Novedades.tsx » doRefresh()`
        );
        setRefreshing(false);
      });
  };

  useEffect(() => {
    if (!refreshing && ionRefresherRef.current) {
      ionRefresherRef.current!.complete();
    }
  }, [refreshing]);

  useEffect(() => {
    doRefresh(false);
    // eslint-disable-next-line
  }, []);

  const abrirUrl = (url: string) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  interface SubtituloProps {
    fecha: string | undefined;
    fijado: boolean | undefined;
  }

  const Subtitulo: React.FC<SubtituloProps> = ({ fecha, fijado }) => {
    const _fecha = fecha || "";
    const _fijado: number = typeof fijado === "number" ? fijado : 0;
    return (
      <IonCardSubtitle>
        <IonItem lines="none">
          <IonLabel no-padding>
            <IonText color="medium">
              <small>
                {/* {formatDistanceStrict(new Date(_fecha), new Date(), { locale: es, unit: "day", addSuffix: true })} */}
                {_fecha && format(parse(_fecha.substring(0, 10), "yyyy-MM-dd", new Date()), "dd/MM/yyyy")}
              </small>
            </IonText>
          </IonLabel>
          {
            _fijado === 1 && <IonIcon slot="end" icon={pinOutline} />
          }
        </IonItem>
      </IonCardSubtitle>
    )
  };

  return (
    <IonPage id="novedades">
      <IonHeader translucent={true} no-border>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <LogoAaot />
          <IonTitle>Novedades</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={() => doRefresh(true)}>
          <IonRefresherContent />
        </IonRefresher>
        <IonToast
          isOpen={showCompleteToast}
          message="Novedades actualizadas"
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
        <IonToolbar className="toolbar-sponsor ion-no-padding" style={{ cursor: "pointer" }} onClick={() => setShowSponsor(true)}>
          <img src={`${process.env.REACT_APP_API_FOTOS}/novedades-bago.jpg`} alt="" />
          <IonButtons slot="end">
            <IonButton fill="outline" shape="round" size="small" color="primary" onClick={() => setShowSponsor(true)}>
              <IonIcon slot="icon-only" icon={searchOutline} size="small" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonGrid fixed style={{ paddingTop: '16px' }}>
          {
            isLoading() && (
              <IonSpinner name="crescent" />
            )
          }
          <IonRow>
            {
              novedades.map(novedad => {
                return novedad.imagen
                  ? (
                    <IonCol key={novedad.id} size="12" size-md="6" size-xl="4">
                      <IonCard mode="ios" onClick={() => novedad?.link ? abrirUrl(novedad.link) : null}>
                        <img src={novedad.imagen} alt="" />
                        <IonCardHeader>
                          <IonCardTitle>{novedad.titulo}</IonCardTitle>
                          <Subtitulo fecha={novedad.fecha} fijado={novedad.fijado} />
                        </IonCardHeader>
                        <IonCardContent style={{ textAlign: "justify" }}>
                          {novedad.texto}
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  ) : (
                    <IonCol key={novedad.id} size="12" size-md="6" size-xl="4">
                      <IonCard mode="ios" style={{
                        color: novedad.color || 'black',
                        backgroundColor: novedad.fondo || 'white'
                      }}
                      >
                        <IonCardHeader>
                          <IonCardTitle style={{ color: novedad.color || 'black' }}>
                            {novedad.titulo}
                          </IonCardTitle>
                          <Subtitulo fecha={novedad.fecha} fijado={novedad.fijado} />
                        </IonCardHeader>
                        <IonCardContent>
                          {novedad.texto}
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  );
              })
            }
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonModal
        swipeToClose={true}
        isOpen={showSponsor}
        className="modal-sponsor"
        onDidDismiss={() => setShowSponsor(false)}
      >
        <SponsorPage
          onDismissModal={() => setShowSponsor(false)}
        />
      </IonModal>
    </IonPage>
  );
};

export default Novedades;
